import React, { memo, useCallback, useState, useRef } from 'react';
import cn from 'classnames';
import { Icon, Text } from 'kolkit';
import { FormattedMessage } from 'react-intl';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Menu, MenuItem, Slide, ListItemIcon } from '@mui/material';

import { safeZendeskAPICall } from 'components/zendesk/ZendeskScript'
import useZendeskLink from 'utils/hooks/useZendeskLink';
import { useSelector } from 'utils/redux';

import styles from './HelperBadge.module.scss';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="right" ref={ref} {...props} />;
});

const UserBadge = ({
  labelPosition,
  className,
  size,
  disabled,
  onOpen,
  fullWidth,
  themeCollapse,
}) => {
  const positionRef = useRef(null);

  const faqLink = useZendeskLink();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = !!anchorEl;

  const { userCanViewFaq, profile, locale } = useSelector(({ user, env }) => ({
    userCanViewFaq: user?.licence?.isActive,
    profile: user.profile,
    locale: env.locale,
  }));

  const handleOpen = useCallback(
    event => {
      if (disabled) return false;
      if (onOpen) onOpen(!!event.currentTarget);
      setAnchorEl(event.currentTarget);
    },
    [disabled, onOpen]
  );

  const handleClose = useCallback(
    () => setAnchorEl(null),
    []
  );

  const handleOpenZendesk = useCallback(
    async () => {
      handleClose();
      await safeZendeskAPICall("messenger:set", "locale", locale.split('-')[0]);
      await safeZendeskAPICall("messenger:set", "conversationFields", [
        { name: `${profile.firstName} ${profile.lastName}` },
        { email: profile.email },
        { organization: profile.companyName },
      ]);
      await safeZendeskAPICall('messenger', 'open');
    },
    [handleClose, locale, profile]
  );

  const cnWrapper = cn(
    styles.wrapper,
    {
      [styles.fullWidth]: fullWidth,
      [styles.labelTop]: labelPosition === 'top'
    },
    className
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={cnWrapper}>
        <div className={styles.triggerWrapper} ref={positionRef}>
          <Icon
            label="question-circle"
            size="huge"
            onClick={handleOpen}
            isButton
            active={anchorEl}
            activeFill="var(--color-primary-400)"
          />
          <Menu
            classes={{
              paper: styles.paper,
              list: styles.list,
            }}
            id={`helper_badge_${size}`}
            elevation={0}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            themeCollapse={themeCollapse}
            TransitionComponent={Transition}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 35,
              horizontal: -14,
            }}
            disableAutoFocusItem
          >
              {userCanViewFaq && (
              <MenuItem
                onClick={handleClose}
                component="a"
                href={faqLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemIcon style={{ minWidth: 28 }}>
                  <Icon label="book" size="big" />
                </ListItemIcon>
                <Text tag="span">
                  <FormattedMessage id="navBar.helpCenter" />
                </Text>
              </MenuItem>
            )}
            <MenuItem onClick={handleOpenZendesk}>
              <ListItemIcon style={{ minWidth: 28 }}>
                <Icon label="comment-lines" size="big" />
              </ListItemIcon>
              <Text tag="span">
                <FormattedMessage id="navBar.contactSupport" />
              </Text>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default memo(UserBadge);
